import React from "react";
import Layout from "../components/Layout";

const IndexPage = () => {
  return (
    <Layout />
  );
};

export default IndexPage;
